import firstscreen from "../images/firstscreen.jpg";
import campaigns from "../images/campaigns.jpg";
import campaignStructure from "../images/campaign-structure.jpg";
import campaignReport from "../images/campaign-report.jpg";
import Reporting from "../images/Reporting.jpg";
import reportingTwo from "../images/Reporting-two.jpg";
import campaignTree from "../images/campaign-tree.jpg";
import periodMetrics from "../images/period-metrics.jpg";
import period from "../images/period.jpg";
import metrics from "../images/metrics.jpg";
import metricsSelected from "../images/metrics-selected.jpg";
import exportSettings from "../images/export-settings.jpg";
import permalink from "../images/permalink.png";
import availableReports from "../images/available-reports.jpg";
import viewsReport from "../images/views-report.jpg";
import viewsReportTwo from "../images/views-report-two.jpg";
import viewsReportThree from "../images/views-report-three.jpg";
import conversionReport from "../images/report-conversions.jpg";
import auditoriaOverlapping from "../images/auditoria-overlapping.jpg";
import auditoriaOverlappingTwo from "../images/auditoria-overlapping two.jpg";
import byCampaigns from "../images/bycampaigns.jpg";
import byCampaignsTwo from "../images/bycampaignstwo.jpg";
import bySites from "../images/bysites.jpg";
import bySitesTwo from "../images/bysitestwo.jpg";
import byInsertions from "../images/byinsertions.jpg";
import byInsertionsTwo from "../images/byinsertionstwo.jpg";
import conversionReportTwo from "../images/conversion-report-two.jpg";
import conversionReportThree from "../images/conversion-report-three.jpg";
import conversionReportFour from "../images/conversion-report-four.jpg";
import conversionReportFive from "../images/conversion-report-five.jpg";
import conversionReportSix from "../images/conversion-report-six.jpg";
import conversionReportSeven from "../images/conversion-report-seven.jpg";
import technologiesReport from "../images/technologies-report.jpg";
import geoReport from "../images/geo-report.jpg";
import audioriaReport from "../images/auditoria-report.jpg";
import conversions from "../images/conversions.jpg";
import automatization from "../images/automatization.jpg";
import automatizationTwo from "../images/automatization-two.jpg";
import automatizationThree from "../images/automatization-three.jpg";
import automatizationFour from "../images/automatization-four.jpg";

import Benchmark1 from "../images/Benchmark1.jpg";
import Benchmark2 from "../images/Benchmark2.jpg";
import Benchmark3 from "../images/Benchmark3.jpg";
import Benchmark4 from "../images/Benchmark4.jpg";
import Benchmark5 from "../images/Benchmark5.jpg";
import Benchmark6 from "../images/Benchmark6.jpg";
import Benchmark7 from "../images/Benchmark7.jpg";
import Benchmark8 from "../images/Benchmark8.jpg";
import Benchmark9 from "../images/Benchmark9.jpg";
import Benchmark10 from "../images/Benchmark10.jpg";
import Benchmark11 from "../images/Benchmark11.jpg";
import Benchmark12 from "../images/Benchmark12.jpg";
import Benchpict1 from "../images/benchpict1.jpg";
import Benchpict2 from "../images/benchpict2.jpg";
import Benchpict3 from "../images/benchpict3.jpg";

export const sectionsTexts = {
    wcmStructure: {
        title: "Интерфейс WCM содержит 5 основных вкладок:",
        image: firstscreen,
        classes: "section section__not-homepage",
        id: "wcm-structure",
    },
    campaigns: {
        title: "Campaigns (Кампании)",
        image: campaigns,
        classes: "section section__not-homepage",
        id: "section-heading",
    },
    campaignStructure: {
        title: "Структура кампании",
        image: campaignStructure,
        classes: "section section__not-homepage",
        id: "campaigns",
    },
    campaignsReport: {
        title: "Отчет по кампании",
        image: campaignReport,
        classes: "section section__not-homepage",
        id: "campaigns",
    },
    reports: {
        title: "Reporting (Отчеты)",
        image: Reporting,
        imageTwo: reportingTwo,
        classes: "section section__not-homepage",
        id: "reporting",
    },
	benchmarks: {
        title: "Benchmarks 2022",
        image1: Benchmark1,
        image2: Benchmark2,
        image3: Benchmark3,
        image4: Benchmark4,
        image5: Benchmark5,
        image6: Benchmark6,
        image7: Benchmark7,
        image8: Benchmark8,
        image9: Benchmark9,
        image10: Benchmark10,
        image11: Benchmark11,
        image12: Benchmark12,
        pict1: Benchpict1,
        pict2: Benchpict2,
        pict3: Benchpict3,
        classes: "section section__not-homepage",
        id: "benchmarks",
    },
    campaignTree: {
        title: "Campaign tree",
        image: campaignTree,
        classes: "section section__not-homepage",
        id: "campaign-tree",
    },
    periodMetrics: {
        title: "Настройка периода и метрик",
        image: periodMetrics,
        classes: "section section__not-homepage",
        id: "settings",
    },
    period: {
        title: "Настройка периода",
        image: period,
        classes: "section section__not-homepage",
        id: "settings",
    },
    metrics: {
        title: "Настройка метрик",
        image: metrics,
        imageTwo: metricsSelected,
        classes: "section section__not-homepage",
        id: "settings",
    },
    xlsxReports: {
        title: "выгрузка отчетов .xlsx",
        image: exportSettings,
        classes: "section section__not-homepage",
        id: "xlsx-reports",
    },
    permalinks: {
        title: "Формирование гостевых ссылок",
        image: permalink,
        classes: "section section__not-homepage",
        id: "permalinks",
    },
    availableReports: {
        title: "Встроенные доступные отчеты",
        image: availableReports,
        classes: "section section__not-homepage",
        id: "available-reports",
    },
    auditoriaOverlapping: {
        title: "Отчет по пересечению аудиторий",
        image: auditoriaOverlapping,
        imageTwo: auditoriaOverlappingTwo,
        classes: "section section__not-homepage",
        id: "auditoria-overlapping",
    },
    byCampaigns: {
        title: "ОТЧЕТ ПО ПЕРЕСЕЧЕНИЮ АУДИТОРИЙ НА УРОВНЕ КАМПАНИЙ",
		image: byCampaigns,
        imageTwo: byCampaignsTwo,
    },
    bySites: {
        title: "ОТЧЕТ ПО ПЕРЕСЕЧЕНИЮ АУДИТОРИЙ НА УРОВНЕ ПЛОЩАДОК",
		image: bySites,
        imageTwo: bySitesTwo,
    },
    byInsertions: {
        title: "ОТЧЕТ ПО ПЕРЕСЕЧЕНИЮ АУДИТОРИЙ НА УРОВНЕ ИНСЕРШИНОВ",
		image: byInsertions,
        imageTwo: byInsertionsTwo,
    },
    frequencyReport: {
        title: "Распределение показов, кликов, конверсий по\u00A0частотам",
        image: viewsReport,
        imageTwo: viewsReportTwo,
        imageThree: viewsReportThree,
        classes: "section section__not-homepage",
        id: "views-report",
    },
    technologyReport: {
        title: "Отчет по технологиям",
        image: technologiesReport,
        classes: "section section__not-homepage",
        id: "technology-report",
    },
    auditoriaReport: {
        title: "Отчет по аудиториям",
        image: audioriaReport,
        classes: "section section__not-homepage",
        id: "auditoria-report",
    },
    geoReport: {
        title: "Отчет по географическому распределению",
        image: geoReport,
        classes: "section section__not-homepage",
        id: "geo-report",
    },
    conversionReport: {
        title: "Конверсионные теги",
        image: conversionReport,
        classes: "section section__not-homepage",
        id: "conversion-report",
    },
    conversions: {
        title: "conversions",
        image: conversions,
        classes: "section section__not-homepage",
        id: "conversion",
    },
    conversionTags: {
        title: "ОТЧЕТ ПО КОНВЕРСИЯМ И КОНВЕРСИОННЫМ ТЕГАМ",
        image: conversionReportTwo,
        imageTwo: conversionReportThree,
        imageThree: conversionReportFour,
        imageFour: conversionReportFive,
        imageFive: conversionReportSix,
        imageSix: conversionReportSeven,
        classes: "section section__not-homepage",
        id: "conversion-tags",
    },
    automatization: {
        title: "Автоматизация отчетов",
        image: automatization,
        imageTwo: automatizationTwo,
        imageThree: automatizationThree,
        imageFour: automatizationFour,

        classes: "section section__not-homepage",
        id: "automatization",
    },
    technical: {
        title: "Основные технические требования",
		codeShit: 'document.querySelector(".clickarea").addEventListener("click", function(){',
		codeShit2: 'document.querySelector(".somearea").addEventListener("mouseenter", function(){',

        classes: "section section__not-homepage",
        id: "technical",
    },
    allMetrics: {
        title: "Описание метрик",
        classes: "section section__not-homepage",
        id: "metrics",
    },
    notFound: {
        title: "Страница не найдена",
        classes: "section section__not-homepage",
        id: "not-found",
    },
};
